<template>
    <div v-if="shows" class="listContainer">
        <div v-for="item in shows" :key="item.slug" class="simpleContainer">
              <router-link :to="{name:'Show', params:{slug: item.slug}}">
            <div class="roundButton">
                <img :src="item.icon" alt="" width="60px">
            </div>
                </router-link>
              <router-link :to="{name:'Show', params:{slug: item.slug}}">
            <div class="podcastData">
                <div class="podcastTitle">{{ item.title }}</div>
                <div class="timeSlot">{{ item.custom_time_slots }}</div>
                <!-- <div class="showDescription">{{ item.longDesc.slice(0,100) }}</div> -->
                <div class="showDescription">{{ item.short_description }}</div>
                
            </div>
                </router-link>
            
               
            
        </div>
    </div>
    <div v-else class="loadingContainer"><TheLoading/></div>    
</template>

<script>
// @ is an alias to /src
//import axios from "axios";
import TheLoading from '@/components/TheLoading'

export default {
  name: 'Shows',
  components:{
    TheLoading
  },
  computed:{
    shows(){
        return this.$shows
    } 
  },
  
  
}
</script>
